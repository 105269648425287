import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  template: `<router-outlet/>`,
  imports: [RouterOutlet],
})
export class AppComponent {
  title = 'MuKiBasar - App';
  swUpdate = inject(SwUpdate);
  constructor() {}
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      interval(6 * 60 * 60 * 1000).subscribe(() => {
        console.log('checking for update');
        this.swUpdate
          .checkForUpdate()
          .then((newUpdateAvailable: boolean) => {
            if (!newUpdateAvailable) return;
            if (confirm('New version available. Load New Version?')) {
              document.location.reload();
            }
          })
          .catch((err) => console.log(err));
      });
    }
  }
}
