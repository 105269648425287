/// <reference types="@angular/localize" />

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import '@angular/common/locales/global/de';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { createAppConfig } from './app/app.config';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

fetch('/assets/config.json')
  .then((resp) => resp.json())
  .then((config) => {
    bootstrapApplication(AppComponent, createAppConfig(config));
  });

